<template>
    <div class="citation_box">
        <div class="box">
            <div class="box_part" v-bind:style="{ minHeight: Height - 40 + 'px' }">
                <div class="user_message">
                    <div class="tables" style="width: 600px">
                        <h2>作者基本信息</h2>
                        <h3>Author basic information</h3>
                        <div class="user_img">
                            <img :src="headshot" alt="" />
                            <div class="names">
                                <p><span>中文发文名称：</span>{{ zhName }}</p>
                                <p><span>外文发文名称：</span>{{ enName }}</p>
                                <p>
                                    <span>所&ensp;属&ensp;机&ensp;构&ensp;：</span>{{ belongs }}
                                </p>
                            </div>
                            <div class="edits">
                                <el-tooltip class="item" effect="light" content="编辑个人信息" placement="bottom"
                                    style="color: rgb(29, 29, 29)">
                                    <i class="el-icon-edit edit" @click="edit_user"></i>
                                </el-tooltip>
                            </div>
                        </div>
                    </div>

                    <div class="tables" style="width: 700px">
                        <h2>
                            引文指数信息
                            <el-tooltip class="item" effect="light" content="重新追踪" placement="bottom"
                                style="color: rgb(29, 29, 29)">
                                <img class="retrace_img" src="../../assets/imgs/icons/重新追踪retrace.png" alt=""
                                    @click="retrace" />
                            </el-tooltip>
                        </h2>
                        <h3>Citation index information</h3>
                        <div class="citation">
                            <div style="background: #ffe2e6">
                                <img src="../../assets/imgs/引文追踪/Icon.png" alt="" />
                                <p class="number">{{ tableData[0].publication }}</p>
                                <p class="type">发文量</p>
                                <p class="addText">+8% from last month</p>
                            </div>
                            <div style="background: #fff4de">
                                <img src="../../assets/imgs/引文追踪/Icon (1).png" alt="" />
                                <p class="number">{{ tableData[0].cit }}</p>
                                <p class="type">总被引频次</p>
                                <p class="addText">+5% from last month</p>
                            </div>
                            <div style="background: #f4e8ff">
                                <img src="../../assets/imgs/引文追踪/Icon (2).png" alt="" />
                                <p class="number">{{ tableData[0].hNum }}</p>
                                <p class="type">H指数</p>
                                <p class="addText">0.5% from last month</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabs">
                    <el-tabs type="border-card" @tab-click="handleClick">
                        <el-tab-pane>
                            <span slot="label"><i class="el-icon-collection-tag"></i>问题反馈</span>
                            <Question v-if="isRouterAlive"></Question>
                        </el-tab-pane>
                        <el-tab-pane>
                            <span slot="label"><i class="el-icon-star-off"></i> 帮助中心</span>
                            <Help></Help>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </div>
        </div>
        <el-dialog title="个人信息" :visible.sync="dialogFormVisible" :width="'500px'">
            <el-form :model="form">
                <el-form-item label="中文发文名称" :label-width="formLabelWidth">
                    <el-input v-model="form.zh_name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="外文发文名称" :label-width="formLabelWidth">
                    <el-input v-model="form.en_name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="所属机构" :label-width="formLabelWidth">
                    <el-input v-model="form.be_name" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <el-dialog :width="'400px'" title="提示" :visible.sync="innerVisible" append-to-body>
                <p style="line-height: 30px">
                    1、修改所属机构可能造成原机构和现机构并存的情况。
                </p>
                <p style="line-height: 30px">
                    2、同时如果该机构目前未开通引文追踪功能，可能存在数据不全的情况。
                </p>
                <div slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="confirmEdit">确 定 修 改</el-button>
                </div>
            </el-dialog>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="innerVisible = true">修改</el-button>
            </div>
        </el-dialog>
        <el-dialog title="提示" :visible.sync="centerDialogVisible" :width="'500px'" center>
            <p class="prompt">
                提示：重新追踪需要一定时间，我们会将追踪结果放在任务列表————重新追踪任务页面，追踪完成会及时通知，方便您随时查看和关注？
            </p>
            <p class="inquire">确认重新追踪？</p>
            <el-dialog :width="'400px'" title="提示" :visible.sync="innerVisible01" append-to-body>
                <p style="line-height: 30px; text-indent: 2em">
                    追踪成功！请在微信接收到完成信息后在
                </p>
                <p style="line-height: 30px; text-indent: 2em; color: #B14A75">
                    任务列表-->中文文献任务列表-->重新追踪任务
                </p>
                <p style="line-height: 30px; text-indent: 2em">进行查看！！</p>

                <div slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="confirmEdit01">确 定</el-button>
                </div>
            </el-dialog>
            <span slot="footer" class="dialog-footer">
                <el-button @click="centerDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="determine">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import Question from "./component/question.vue";
import Help from "./component/help.vue";
import { personalMessage } from "@/Api/userMessage";
import {
    confirmUser,
    getUser,
    judge_user,
    user_mes,
    user_article,
    delete_article,
    new_article,
    new_cited,
    article_export,
    article_import,
    auth_task,
    auth_task_post,
    retracker,
    article_detail,
    article_update,
    retracker_get,
} from "@/Api/unquote";

export default {
    provide() {
        return {
            reload: this.reload,
            Height: 0,
        };
    },
    data() {
        return {
            Height: 0,
            headshot: "",
            zhName: "",
            enName: "",
            belongs: "",
            formLabelWidth: "120px",
            dialogFormVisible: false,
            innerVisible: false,
            form: {
                zh_name: "",
                en_name: "",
                be_name: "",
            },
            isRouterAlive: true,
            centerDialogVisible: false,
            innerVisible01: false,
            tableData: [
                {
                    publication: 0,
                    cit: 0,
                    hNum: 0,
                },
            ],
        };
    },
    mounted() {
        //动态设置内容高度 让footer始终居底   header+footer的高度是190
        // console.log(this.name)
        this.Height = document.documentElement.clientHeight - 140; //监听浏览器窗口变化
        window.onresize = () => {
            this.Height = document.documentElement.clientHeight - 140;
        };
    },
    created() {
        this.message();
        this.user_mes();
    },
    methods: {
        user_mes() {
            let user_ids = {
                user_openid: localStorage.getItem("code"),
            };
            judge_user(user_ids)
                .then((res) => {
                    console.log(res);
                    this.zhName = res.data.user_mes[0].ch_user_name;
                    this.enName = res.data.user_mes[0].en_user_name;
                    this.belongs = res.data.user_mes[0].institution_name;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        message() {
            personalMessage()
                .then((res) => {
                    this.headshot = res.data.data.avatarUrl;
                })
                .catch((err) => {
                    console.log(err);
                });

            let register_data = {
                ch_user_name: localStorage.getItem("name"),
                en_user_name: localStorage.getItem("en_name"),
                institution_name: localStorage.getItem("org_name"),
                user_openid: localStorage.getItem("code"),
                article_type: "en",
                type: 1,
            };
            user_mes(register_data)
                .then((res) => {
                    // console.log(res);
                    this.tableData[0].publication = res.data.all_article_count;
                    this.tableData[0].cit = res.data.article_cited_count;
                    this.tableData[0].hNum = res.data.H_num;
                    let register_data_zh = {
                        ch_user_name: localStorage.getItem("name"),
                        en_user_name: localStorage.getItem("en_name"),
                        institution_name: localStorage.getItem("org_name"),
                        user_openid: localStorage.getItem("code"),
                        article_type: "zh",
                        type: 1,
                    };
                    user_mes(register_data_zh)
                        .then((res) => {
                            // console.log(res);
                            this.tableData[0].publication =
                                this.tableData[0].publication + res.data.all_article_count;
                            this.tableData[0].cit =
                                this.tableData[0].cit + res.data.article_cited_count;
                            this.tableData[0].hNum = this.tableData[0].hNum + res.data.H_num;
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        edit_user() {
            this.dialogFormVisible = true;
            this.form = {
                zh_name: this.zhName,
                en_name: this.enName,
                be_name: this.belongs,
            };
        },
        confirmEdit() {
            let register_data = {
                ch_user_name: this.form.zh_name,
                en_user_name: this.form.en_name,
                institution_name: this.form.be_name,
                user_openid: localStorage.getItem("code"),
                institution_code: localStorage.getItem("org_ode"),
            };
            confirmUser(register_data)
                .then((res) => {
                    console.log(res);
                    this.dialogFormVisible = false;
                    this.innerVisible = false;
                    this.$message({
                        showClose: true,
                        message: "个人信息修改成功！",
                        type: "success",
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        handleClick(tab, event) {
            console.log(tab.index);
        },
        reload() {
            this.isRouterAlive = false;
            this.$nextTick(function () {
                this.isRouterAlive = true;
            });
        },
        // 重新追踪
        retrace() {
            this.centerDialogVisible = true;
        },
        determine() {
            let user_article = {
                user_openid: localStorage.getItem("code"),
                article_type: "zh",
            };
            retracker_get(user_article)
                .then((res) => {
                    console.log(res);
                    this.innerVisible01 = true;
                    //   this.$message({
                    //     message:
                    //       "追踪成功！请在微信接收到完成信息后在  任务列表-->中文文献任务列表-->重新追踪任务 中查看！！",
                    //     type: "success",
                    //   });
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        confirmEdit01() {
            this.centerDialogVisible = false;
            this.innerVisible01 = false;
        },
    },
    components: {
        Question,
        Help,
    },
};
</script>

<style lang="less" scoped>
.citation_box {
    min-width: 1360px;
    display: flex;
    color: rgb(29, 29, 29);

    .box {
        width: 100%;

        .box_part {
            width: 100%;
        }
    }
}

.user_message {
    width: 100%;
    padding-bottom: 20px;
    max-width: 1500px;
    min-width: 1070px;
    margin: auto;
    display: flex;
    justify-content: space-between;
}

.edit {
    font-size: 21px;
    position: relative;
    top: 8px;
    left: -20px;
}

.edit:hover {
    color: #B14A75 !important;
}

.tables {
    //   margin: 20px 0;
    background: white;
    padding: 10px 30px;
    border-radius: 16px;
    max-height: 350px;

    h2 {
        font-size: 18px;
        // font-weight: 700;
        line-height: 50px;
        // font-family: "微软雅黑" !important;
    }

    h3 {
        color: #b6bbd1;
        margin-bottom: 20px;
    }

    //   width: 500px !important;
    .user_img {
        display: flex;

        img {
            width: 120px;
            height: 120px;
            border-radius: 60px;
        }

        .names {
            width: 300px;
            padding: 0 20px;

            p {
                line-height: 40px;

                span {
                    width: 100px;
                }
            }
        }
    }

    //   color: #a899e4;
}

.citation {
    display: flex;
    justify-content: space-between;

    div {
        // width: 120px;
        padding: 20px;
        border-radius: 16px;

        img {
            width: 50px;
            // margin: 0 15px 10px 15px;
        }

        p {
            //   text-align: center;
            line-height: 30px;
        }

        .type {}

        .number {
            font-size: 23px;
            font-weight: 700;
            margin-left: 5px;
        }

        .addText {
            color: #3f78ec;
        }
    }
}

.tabs {
    width: 100%;
    padding-top: 20px;
    max-width: 1500px;
    min-width: 1070px;
    margin: auto;
}

.retrace_img {
    cursor: pointer;
    width: 30px;
    height: 30px;
    position: relative;
    left: 20px;
    top: 10px;
}

.prompt {
    margin: 0 50px;
    text-indent: 2em;
    line-height: 30px;
    color: #B14A75;
}

.inquire {
    text-align: center;
    font-size: 18px;
    margin: 30px 0 0 0;
}
</style>