<template>
  <div class="my_help" v-bind:style="{ minHeight: Height + 'px' }">
    <div class="assist">
      <!-- <h2>帮助中心</h2> -->
      <el-tabs v-model="activeName" @tab-click="handleClick" class="help_item">
        <!-- <el-tab-pane label="入门指南" name="entry">入门指南</el-tab-pane> -->
        <el-tab-pane label="使用指南" name="use">
          <el-collapse v-model="activeGuild" @change="handleGuild" accordion>
            <el-collapse-item title="1、作者信息确认：" name="1">
              <div>
                在作者发文信息确认页面需要用户确认自己的发文信息是否正确，在确认发文信息无误后，点击开始追踪，我们将会为您追踪您被收录在知网和Web
                of Science的文章。
              </div>
              <img src="../../../assets/imgs/question/图片1.png" alt="" />
              
            </el-collapse-item>
            <el-collapse-item title="2、文献追踪(可切换中外文文献)" name="2">
              <div>
               文献追踪页面主要是展示中外文文献发文情况、近8年发文量与被引频次统计和发表文献列表
              </div>
              <img src="../../../assets/imgs/question/图片2.png" alt="" />
              <img src="../../../assets/imgs/question/图片3.png" alt="" />
            </el-collapse-item>
            <el-collapse-item title="3、删除" name="3">
              <div>
                在现在列表中选择不属于自己的文章，点击批量删除文献
              </div>
              <img src="../../../assets/imgs/question/图片4.png" alt="" />
            </el-collapse-item>
            <!-- <el-collapse-item title="4、修改识别文献" name="4">
              <div>
                添加需求后点击识别，可对识别后的文献进行修改<br />
                需求类型如果识别有误，可自行修改
              </div>
              <img src="../../../assets/imgs/图片19.png" alt="" />
            </el-collapse-item> -->
            <el-collapse-item title="4、导出" name="5">
              <div>
                导出文件与删除相似，选择想要导出的文献，点击导出即可。
              </div>
              <img src="../../../assets/imgs/question/图片5.png" alt="" />
              
            </el-collapse-item>
            <el-collapse-item title="5、导入" name="6">
              <div>文献导入：</div>
              <div>点击导入，进入文献导入页面，根据页面中的提示导入文献。</div>
              <img src="../../../assets/imgs/question/图片6.png" alt="" />
             <div>导出信息处理：</div>
              <div>当文献导入后，页面跳转人物列表，我们会将文献进行处理，会尽快将所传文献上传到任务列表中。</div>
              <img src="../../../assets/imgs/question/图片7.png" alt="" />
              <div>点击导入可以将已经上传的文献加入到自己的文献列表中，可以在文献列表中看到哦。</div>
              <img src="../../../assets/imgs/question/图片8.png" alt="" />
               <div>想要随时查看任务列表，可以在发表文献列表点击任务列表进行查看哦。</div>
              <img src="../../../assets/imgs/question/图片9.png" alt="" />

            </el-collapse-item>
            <el-collapse-item title="6、排序" name="7">
              <div>
                发表文献列表排序根据被引频次降序、发表时间升序、发表时间降序、最新被引进行排序
              </div>
              <img src="../../../assets/imgs/question/图片10.png" alt="" />
              
            </el-collapse-item>
            
            <!-- 
            <el-collapse-item title="" name="5">
              <div></div>
              <img src="../../../assets/imgs/图片12.png" alt="" />
            </el-collapse-item>
             -->
          </el-collapse>
        </el-tab-pane>
        <el-tab-pane label="常见问题" name="issuse">
          <el-collapse v-model="activeNames" @change="handleChange" accordion>
            <el-collapse-item
              title="1、中文文献上传是什么格式的？"
              name="1"
            >
              <div>
               复制知网链接到txt文档中，如果想要上传多篇文献，请换行填写链接
              </div>
              <img src="../../../assets/imgs/question/图片11.png" alt="" />
              <img src="../../../assets/imgs/question/图片12.png" alt="" />
            </el-collapse-item>
            <el-collapse-item title="2、英文文献上传是什么格式的？" name="2">
              <div>
                英文文献允许上传Web of Science导出的纯文本文件和Excel文件，暂不支持制表符分割文件。
              </div>
              <img src="../../../assets/imgs/question/图片13.png" alt="" />
              <img src="../../../assets/imgs/question/图片14.png" alt="" />
              
            </el-collapse-item>
            <el-collapse-item title="3、在电脑上怎样查看导出的文件" name="3">
              <div>
                文件右键打开方式——选择Excel文件——文件就可以直接查看
              </div>
              <img src="../../../assets/imgs/question/图片15.png" alt="" />
              <img src="../../../assets/imgs/question/图片16.png" alt="" />
            </el-collapse-item>
            <el-collapse-item title="4、重新追踪需要多长时间" name="4">
              <div>
                重新追踪文献会在1天内完成，完成后所追踪到的文献会展示在任务列表——重新追踪任务列表中。
              </div>
      
            </el-collapse-item>
            <el-collapse-item
              title="5、重新追踪任务包含什么？"
              name="5"
            >
              <div>
                重新追踪任务是用户重新追踪后最新收录的文献信息，用户可以点击添加到引文追踪发文列表中，进行实时引用数量追踪。
              </div>
              
            </el-collapse-item>
            
            
            <!-- 
            <el-collapse-item title="" name="5">
              <div></div>
              <img src="../../../assets/imgs/图片12.png" alt="" />
            </el-collapse-item>
             -->
          </el-collapse>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: "issuse",
      activeNames: [""],
      activeGuild: [""],
      Height: 0,
      // Guild
    };
  },
  mounted() {
    //动态设置内容高度 让footer始终居底   header+footer的高度是190
    // console.log(this.name)
    this.Height = document.documentElement.clientHeight - 400; //监听浏览器窗口变化
    window.onresize = () => {
      this.Height = document.documentElement.clientHeight - 400;
    };
  },
  methods: {
    handleClick(tab, event) {
      // console.log(tab, event);
    },
    handleChange(val) {
      // console.log(val);
    },
    handleGuild(val) {
      // console.log(val);
    },
  },
};
</script>

<style lang="less" scoped>
.my_help {
  // margin: 20px;
  // min-width: 901px;

  background-color: #fff;
  display: flex;
  justify-content: center;
  // padding: 0 20%;
  .assist {
    // padding: 0 20px;
    // margin: 10px 0;
    width: 901px;
    h2 {
      height: 50px;
      line-height: 50px;
      // padding-left: 5px;
    }
    .question {
      li {
        height: 50px;
        line-height: 50px;
      }
    }
  }
}
.help_item {
  // padding: 0 20px;
  // width: 50%;
  .el-tab-pane {
    width: 100%;
    .el-collapse {
      width: 100%;
    }
  }
  div {
    // text-indent:2em;
  }
  img {
    width: 100%;
  }
  /deep/ .el-tabs__content {
    min-height: 300px;
  }
  .is-active {
    color: #B14A75;
  }
}
/deep/ .el-tabs__item.is-active {
  color: #B14A75;
}
/deep/ .el-tabs__active-bar {
  background-image: linear-gradient(to bottom, #B14A75, #B14A75);
}
.a {
  background: rgb(255, 208, 0);
}
</style>